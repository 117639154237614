import * as React from "react"
import { Link, HeadFC } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import GetThePrices from "../../components/gettheprices/gettheprices"
import NewsletterSignup from "../../components/newslettersignup/newslettersignup"
import Tag from "../../components/tag/tag"
import "./blog.scss"
import strings from "../../i18n"
import Img from "gatsby-image"
import { navigate } from 'gatsby';

const BlogPage = ({data}) => {
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout chat={true}>      
      <div className="blog blog-index section">
        <div className="container">
          <Tag type="yellow">{strings.homefounder_blog}</Tag>
          <h1>{strings.homefounder_letter}</h1>
          <h4>{strings.your_guide_to_new_construction}</h4>
          <div className="blog-posts">
            {posts.map((post, i) => {  
              const frontmatter = post.node.frontmatter
              const authorImgFluid = frontmatter.authorImage.childImageSharp.fluid
              const featuredImgFluid = frontmatter.featuredImage.childImageSharp.fluid
              const tags = frontmatter.tags
              const postUrl = `/blog/${frontmatter.slug}`
              var tagElems = []
              for (var tagIndex in tags){
                const tag = tags[tagIndex]
                tagElems.push(<div className="tag body2">{tag}</div>)
              }
              return (<div className="post-item" onClick={() => {navigate(postUrl)}}>
              <Img fluid={featuredImgFluid} className="img"/>
              <div className="details">
                <div className="tags">{tagElems}</div>
                <Link to={postUrl} className="h4">{frontmatter.title}</Link>
                <div className="body2">{frontmatter.description}</div>
                <div className="author-details"><Img fluid={authorImgFluid} />{frontmatter.author}<div className="sep"></div>{frontmatter.date}</div>
              </div>
            </div>) 
              })}
          </div>
        </div>
      </div>
      <NewsletterSignup/>
      <GetThePrices/>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 10, sort: {order: DESC, fields: frontmatter___date}) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            author
            authorTitle
            description
            tags
            authorImage {
              childImageSharp {
                fluid(maxWidth: 96) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }`

export default BlogPage

export const Head: HeadFC = () => <SEO title="Blog"/>
